
import { createRouter, createWebHashHistory } from 'vue-router';

import { useAuth } from 'src/composables/auth';


function passRouteToProps(route){
	return {
		queryParams: route.query,
		fieldName: route.params.fieldName, 
		fieldValue: route.params.fieldValue
	}
}


let routes = [
	//Dashboard routes


//datadaerah routes
			{
				path: '/datadaerah/:fieldName?/:fieldValue?',
				name: 'datadaerahlist',
				component: () => import('./pages/datadaerah/list.vue'), 
				props: route => passRouteToProps(route)
			},
	
			{ 
				path: '/datadaerah/view/:id', 
				name: 'datadaerahview', 
				component: () => import('./pages/datadaerah/view.vue'), 
				props: true
			},
		
			{ 
				path: '/datadaerah/add', 
				name: 'datadaerahadd', 
				component: () => import('./pages/datadaerah/add.vue'), 
				props: true
			},
	
			{ 
				path: '/datadaerah/edit/:id', 
				name: 'datadaerahedit', 
				component: () => import('./pages/datadaerah/edit.vue'), 
				props: true
			},
		

//datajawatan routes
			{
				path: '/datajawatan/:fieldName?/:fieldValue?',
				name: 'datajawatanlist',
				component: () => import('./pages/datajawatan/list.vue'), 
				props: route => passRouteToProps(route)
			},
	
			{ 
				path: '/datajawatan/view/:id', 
				name: 'datajawatanview', 
				component: () => import('./pages/datajawatan/view.vue'), 
				props: true
			},
		
			{ 
				path: '/datajawatan/add', 
				name: 'datajawatanadd', 
				component: () => import('./pages/datajawatan/add.vue'), 
				props: true
			},
	
			{ 
				path: '/datajawatan/edit/:id', 
				name: 'datajawatanedit', 
				component: () => import('./pages/datajawatan/edit.vue'), 
				props: true
			},
		

//datapetugas routes
			{
				path: '/datapetugas/:fieldName?/:fieldValue?',
				name: 'datapetugaslist',
				component: () => import('./pages/datapetugas/list.vue'), 
				props: route => passRouteToProps(route)
			},
	
			{ 
				path: '/datapetugas/view/:id', 
				name: 'datapetugasview', 
				component: () => import('./pages/datapetugas/view.vue'), 
				props: true
			},
		
			{ 
				path: '/datapetugas/add', 
				name: 'datapetugasadd', 
				component: () => import('./pages/datapetugas/add.vue'), 
				props: true
			},
	
			{ 
				path: '/datapetugas/edit/:id', 
				name: 'datapetugasedit', 
				component: () => import('./pages/datapetugas/edit.vue'), 
				props: true
			},
		
			{
				path: '/datapetugas/uas/:fieldName?/:fieldValue?',
				name: 'datapetugasuas',
				component: () => import('./pages/datapetugas/uas.vue'), 
				props: route => passRouteToProps(route)
			},
	
			{
				path: '/datapetugas/ubbm/:fieldName?/:fieldValue?',
				name: 'datapetugasubbm',
				component: () => import('./pages/datapetugas/ubbm.vue'), 
				props: route => passRouteToProps(route)
			},
	
			{
				path: '/datapetugas/ubbi/:fieldName?/:fieldValue?',
				name: 'datapetugasubbi',
				component: () => import('./pages/datapetugas/ubbi.vue'), 
				props: route => passRouteToProps(route)
			},
	
			{
				path: '/datapetugas/umub/:fieldName?/:fieldValue?',
				name: 'datapetugasumub',
				component: () => import('./pages/datapetugas/umub.vue'), 
				props: route => passRouteToProps(route)
			},
	
			{ 
				path: '/datapetugas/edituas/:id', 
				name: 'datapetugasedituas', 
				component: () => import('./pages/datapetugas/edituas.vue'), 
				props: true
			},
		
			{ 
				path: '/datapetugas/viewpetugas/:id', 
				name: 'datapetugasviewpetugas', 
				component: () => import('./pages/datapetugas/viewpetugas.vue'), 
				props: true
			},
		
			{ 
				path: '/datapetugas/editubbm/:id', 
				name: 'datapetugaseditubbm', 
				component: () => import('./pages/datapetugas/editubbm.vue'), 
				props: true
			},
		
			{ 
				path: '/datapetugas/editubbi/:id', 
				name: 'datapetugaseditubbi', 
				component: () => import('./pages/datapetugas/editubbi.vue'), 
				props: true
			},
		
			{ 
				path: '/datapetugas/editumub/:id', 
				name: 'datapetugaseditumub', 
				component: () => import('./pages/datapetugas/editumub.vue'), 
				props: true
			},
		

//datapusat routes
			{
				path: '/datapusat/:fieldName?/:fieldValue?',
				name: 'datapusatlist',
				component: () => import('./pages/datapusat/list.vue'), 
				props: route => passRouteToProps(route)
			},
	
			{ 
				path: '/datapusat/view/:id', 
				name: 'datapusatview', 
				component: () => import('./pages/datapusat/view.vue'), 
				props: true
			},
		
			{ 
				path: '/datapusat/add', 
				name: 'datapusatadd', 
				component: () => import('./pages/datapusat/add.vue'), 
				props: true
			},
	
			{ 
				path: '/datapusat/edit/:id', 
				name: 'datapusatedit', 
				component: () => import('./pages/datapusat/edit.vue'), 
				props: true
			},
		
			{
				path: '/datapusat/menupusat/:fieldName?/:fieldValue?',
				name: 'datapusatmenupusat',
				component: () => import('./pages/datapusat/menupusat.vue'), 
				props: route => passRouteToProps(route)
			},
	

//datasekolah routes
			{
				path: '/datasekolah/:fieldName?/:fieldValue?',
				name: 'datasekolahlist',
				component: () => import('./pages/datasekolah/list.vue'), 
				props: route => passRouteToProps(route)
			},
	
			{ 
				path: '/datasekolah/view/:id', 
				name: 'datasekolahview', 
				component: () => import('./pages/datasekolah/view.vue'), 
				props: true
			},
		
			{ 
				path: '/datasekolah/add', 
				name: 'datasekolahadd', 
				component: () => import('./pages/datasekolah/add.vue'), 
				props: true
			},
	
			{ 
				path: '/datasekolah/edit/:id', 
				name: 'datasekolahedit', 
				component: () => import('./pages/datasekolah/edit.vue'), 
				props: true
			},
		

//datasukarelawan routes
			{
				path: '/datasukarelawan/:fieldName?/:fieldValue?',
				name: 'datasukarelawanlist',
				component: () => import('./pages/datasukarelawan/list.vue'), 
				props: route => passRouteToProps(route)
			},
	
			{ 
				path: '/datasukarelawan/view/:id', 
				name: 'datasukarelawanview', 
				component: () => import('./pages/datasukarelawan/view.vue'), 
				props: true
			},
		
			{ 
				path: '/datasukarelawan/add', 
				name: 'datasukarelawanadd', 
				component: () => import('./pages/datasukarelawan/add.vue'), 
				props: true
			},
	
			{ 
				path: '/datasukarelawan/edit/:id', 
				name: 'datasukarelawanedit', 
				component: () => import('./pages/datasukarelawan/edit.vue'), 
				props: true
			},
		

//permissions routes
			{
				path: '/permissions/:fieldName?/:fieldValue?',
				name: 'permissionslist',
				component: () => import('./pages/permissions/list.vue'), 
				props: route => passRouteToProps(route)
			},
	
			{ 
				path: '/permissions/view/:id', 
				name: 'permissionsview', 
				component: () => import('./pages/permissions/view.vue'), 
				props: true
			},
		
			{ 
				path: '/permissions/add', 
				name: 'permissionsadd', 
				component: () => import('./pages/permissions/add.vue'), 
				props: true
			},
	
			{ 
				path: '/permissions/edit/:id', 
				name: 'permissionsedit', 
				component: () => import('./pages/permissions/edit.vue'), 
				props: true
			},
		

//roles routes
			{
				path: '/roles/:fieldName?/:fieldValue?',
				name: 'roleslist',
				component: () => import('./pages/roles/list.vue'), 
				props: route => passRouteToProps(route)
			},
	
			{ 
				path: '/roles/view/:id', 
				name: 'rolesview', 
				component: () => import('./pages/roles/view.vue'), 
				props: true
			},
		
			{ 
				path: '/roles/add', 
				name: 'rolesadd', 
				component: () => import('./pages/roles/add.vue'), 
				props: true
			},
	
			{ 
				path: '/roles/edit/:id', 
				name: 'rolesedit', 
				component: () => import('./pages/roles/edit.vue'), 
				props: true
			},
		

//tbpep routes
			{
				path: '/tbpep/:fieldName?/:fieldValue?',
				name: 'tbpeplist',
				component: () => import('./pages/tbpep/list.vue'), 
				props: route => passRouteToProps(route)
			},
	
			{ 
				path: '/tbpep/view/:id', 
				name: 'tbpepview', 
				component: () => import('./pages/tbpep/view.vue'), 
				props: true
			},
		
			{ 
				path: '/tbpep/add', 
				name: 'tbpepadd', 
				component: () => import('./pages/tbpep/add.vue'), 
				props: true
			},
	
			{ 
				path: '/tbpep/edit/:id', 
				name: 'tbpepedit', 
				component: () => import('./pages/tbpep/edit.vue'), 
				props: true
			},
		
			{
				path: '/tbpep/menupep/:fieldName?/:fieldValue?',
				name: 'tbpepmenupep',
				component: () => import('./pages/tbpep/menupep.vue'), 
				props: route => passRouteToProps(route)
			},
	

//tbstatusborang routes
			{
				path: '/tbstatusborang/:fieldName?/:fieldValue?',
				name: 'tbstatusboranglist',
				component: () => import('./pages/tbstatusborang/list.vue'), 
				props: route => passRouteToProps(route)
			},
	
			{ 
				path: '/tbstatusborang/view/:id', 
				name: 'tbstatusborangview', 
				component: () => import('./pages/tbstatusborang/view.vue'), 
				props: true
			},
		
			{ 
				path: '/tbstatusborang/add', 
				name: 'tbstatusborangadd', 
				component: () => import('./pages/tbstatusborang/add.vue'), 
				props: true
			},
	
			{ 
				path: '/tbstatusborang/edit/:id', 
				name: 'tbstatusborangedit', 
				component: () => import('./pages/tbstatusborang/edit.vue'), 
				props: true
			},
		

//tbtnt routes
			{
				path: '/tbtnt/:fieldName?/:fieldValue?',
				name: 'tbtntlist',
				component: () => import('./pages/tbtnt/list.vue'), 
				props: route => passRouteToProps(route)
			},
	
			{ 
				path: '/tbtnt/view/:id', 
				name: 'tbtntview', 
				component: () => import('./pages/tbtnt/view.vue'), 
				props: true
			},
		
			{ 
				path: '/tbtnt/add', 
				name: 'tbtntadd', 
				component: () => import('./pages/tbtnt/add.vue'), 
				props: true
			},
	
			{ 
				path: '/tbtnt/edit/:id', 
				name: 'tbtntedit', 
				component: () => import('./pages/tbtnt/edit.vue'), 
				props: true
			},
		

//tbtuntutan routes
			{
				path: '/tbtuntutan/:fieldName?/:fieldValue?',
				name: 'tbtuntutanlist',
				component: () => import('./pages/tbtuntutan/list.vue'), 
				props: route => passRouteToProps(route)
			},
	
			{ 
				path: '/tbtuntutan/view/:id', 
				name: 'tbtuntutanview', 
				component: () => import('./pages/tbtuntutan/view.vue'), 
				props: true
			},
		
			{ 
				path: '/tbtuntutan/add', 
				name: 'tbtuntutanadd', 
				component: () => import('./pages/tbtuntutan/add.vue'), 
				props: true
			},
	
			{ 
				path: '/tbtuntutan/edit/:id', 
				name: 'tbtuntutanedit', 
				component: () => import('./pages/tbtuntutan/edit.vue'), 
				props: true
			},
		
			{
				path: '/tbtuntutan/ikhtisas/:fieldName?/:fieldValue?',
				name: 'tbtuntutanikhtisas',
				component: () => import('./pages/tbtuntutan/ikhtisas.vue'), 
				props: route => passRouteToProps(route)
			},
	
			{
				path: '/tbtuntutan/perjalanan/:fieldName?/:fieldValue?',
				name: 'tbtuntutanperjalanan',
				component: () => import('./pages/tbtuntutan/perjalanan.vue'), 
				props: route => passRouteToProps(route)
			},
	
			{
				path: '/tbtuntutan/listlaporan/:fieldName?/:fieldValue?',
				name: 'tbtuntutanlistlaporan',
				component: () => import('./pages/tbtuntutan/listlaporan.vue'), 
				props: route => passRouteToProps(route)
			},
	
			{ 
				path: '/tbtuntutan/editsemak/:id', 
				name: 'tbtuntutaneditsemak', 
				component: () => import('./pages/tbtuntutan/editsemak.vue'), 
				props: true
			},
		
			{
				path: '/tbtuntutan/listsemak/:fieldName?/:fieldValue?',
				name: 'tbtuntutanlistsemak',
				component: () => import('./pages/tbtuntutan/listsemak.vue'), 
				props: route => passRouteToProps(route)
			},
	

//tbtuntutansukarelawan routes
			{
				path: '/tbtuntutansukarelawan/:fieldName?/:fieldValue?',
				name: 'tbtuntutansukarelawanlist',
				component: () => import('./pages/tbtuntutansukarelawan/list.vue'), 
				props: route => passRouteToProps(route)
			},
	
			{ 
				path: '/tbtuntutansukarelawan/view/:id', 
				name: 'tbtuntutansukarelawanview', 
				component: () => import('./pages/tbtuntutansukarelawan/view.vue'), 
				props: true
			},
		
			{ 
				path: '/tbtuntutansukarelawan/add', 
				name: 'tbtuntutansukarelawanadd', 
				component: () => import('./pages/tbtuntutansukarelawan/add.vue'), 
				props: true
			},
	
			{ 
				path: '/tbtuntutansukarelawan/edit/:id', 
				name: 'tbtuntutansukarelawanedit', 
				component: () => import('./pages/tbtuntutansukarelawan/edit.vue'), 
				props: true
			},
		
			{
				path: '/tbtuntutansukarelawan/listlaporansuk/:fieldName?/:fieldValue?',
				name: 'tbtuntutansukarelawanlistlaporansuk',
				component: () => import('./pages/tbtuntutansukarelawan/listlaporansuk.vue'), 
				props: route => passRouteToProps(route)
			},
	
			{ 
				path: '/tbtuntutansukarelawan/editsemaksuk/:id', 
				name: 'tbtuntutansukarelawaneditsemaksuk', 
				component: () => import('./pages/tbtuntutansukarelawan/editsemaksuk.vue'), 
				props: true
			},
		

//users routes
			{
				path: '/users/:fieldName?/:fieldValue?',
				name: 'userslist',
				component: () => import('./pages/users/list.vue'), 
				props: route => passRouteToProps(route)
			},
	
			{ 
				path: '/users/view/:id', 
				name: 'usersview', 
				component: () => import('./pages/users/view.vue'), 
				props: true
			},
		
			{ 
				path: '/account/edit', 
				name: 'usersaccountedit', 
				component: () => import('./pages/account/accountedit.vue'), 
				props: true
			},
	
			{ 
				path: '/account', 
				name: 'usersaccountview', 
				component: () => import('./pages/account/accountview.vue'), 
				props: true
			},
	
			{ 
				path: '/users/add', 
				name: 'usersadd', 
				component: () => import('./pages/users/add.vue'), 
				props: true
			},
	
			{ 
				path: '/users/edit/:id', 
				name: 'usersedit', 
				component: () => import('./pages/users/edit.vue'), 
				props: true
			},
		

			{ 
				path: '/menu_setting', 
				name: 'menu_setting', 
				component: () => import('././pages/custom/menu_setting.vue'), 
				props: true
			},
	
			{ 
				path: '/menu_semak', 
				name: 'menu_semak', 
				component: () => import('././pages/custom/menu_semak.vue'), 
				props: true
			},
	
			{ 
				path: '/laporan', 
				name: 'laporan', 
				component: () => import('././pages/custom/laporan.vue'), 
				props: true
			},
	
	
	
	
	
	{ 
		path:  '/error/forbidden', 
		name: 'forbidden', 
		component: () => import('./pages/errors/forbidden.vue'),
		props: true
	},
	{ 
		path: '/error/notfound', 
		name: 'notfound',
		component: () => import('./pages/errors/pagenotfound.vue'),
		props: true
	},
	{
		path: '/:catchAll(.*)', 
		component: () => import('./pages/errors/pagenotfound.vue')
	}
];

export default () => {
	
const auth = useAuth();

	
	const user = auth.user;
	if(user){
		routes.push({ path: '/', alias: '/home', name: 'home', component: () => import(`./pages/home/home.vue`) });
	}
	else{
		routes.push({ path: '/', alias: '/index', name: 'index', component: () => import('./pages/index/index.vue') });
	}

	const router = createRouter({
		history: createWebHashHistory(),
		routes,
		scrollBehavior(to, from, savedPostion){
			if(savedPostion) return savedPostion;
			return { top:0 }
		}
	});
	
	router.beforeEach((to, from, next) => {
		const user = auth.user;
		let path = to.path;
		let authRequired = auth.pageRequiredAuth(path);
		if (authRequired) {
			if(!user){
				return next({ path: '/',  query: { nexturl: to.fullPath } });
			}
			//authorize user
			if (!auth.canView(path)) {
				return next({path: "/error/forbidden"});
			}
		}

		if(user && to.name == "index"){
			//already logged in, show home when try to access index page
			return next({ path: "/home"});
		}

		//navigate to redirect url if available
		if(to.name == "home" && to.query.nexturl){
			return next({ path: to.query.nexturl});
		}

 		//close dialog from previous page
		//store.closeDialogs('app/closeDialogs');
		
		next();
	});

	return router;
}
